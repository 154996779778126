<template>
    <v-expansion-panel>
        <v-expansion-panel-header>Position Information</v-expansion-panel-header>
        <v-expansion-panel-content v-if="issueChecklist.position_info">
            <v-row v-if="issueChecklist.position_info.cu_uni">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.cu_uni"
						readonly
						label="CU UNI"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.cwid">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.cwid"
						readonly
						label="CWID"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.requisition_number">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.requisition_number"
						readonly
						label="Requisition Number"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.requisition_number_np">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.requisition_numbe_np"
						readonly
						label="Requisition Number - NP"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.requisition_number_pa">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.requisition_numbe_pa"
						readonly
						label="Requisition Number - PA"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.talent_link_number">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.talent_link_number"
						readonly
						label="Talent Link JD Number"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.talent_link_number_np">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.talent_link_number_np"
						readonly
						label="Talent Link JD Number - NP"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.talent_link_number_pa">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.talent_link_number_pa"
						readonly
						label="Talent Link JD Number - PA"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.hrcules_number">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.hrcules_number"
						readonly
						label="HRCULES Number"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.interfolio_number">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.interfolio_number"
						readonly
						label="Interfolio Number"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.candidate_link">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.candidate_link"
						readonly
						label="Candidate Link"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.candidate_link_np">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.candidate_link_np"
						readonly
						label="Candidate Link - NP"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.candidate_link_pa">
				<v-col>
					<v-text-field
						:value="issueChecklist.position_info.candidate_link_pa"
						readonly
						label="Candidate Link - PA"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.offer_extended">
				<v-col>
					<v-date-picker
						:value="issueChecklist.position_info.candidate_link_pa"
						readonly
						no-title
						label="Offer Extended"
					/>
				</v-col>
			</v-row>
			<v-row v-if="issueChecklist.position_info.offer_accepted">
				<v-col>
					<v-date-picker
						:value="issueChecklist.position_info.offer_accepted"
						readonly
						no-title
						label="Offer Accepted"
					/>
				</v-col>
			</v-row>
        </v-expansion-panel-content>
        <v-expansion-panel-content v-else>
            <v-alert class="mt-4" outlined type="info">
                No position information at this time.
            </v-alert>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>

import { computed } from '@vue/composition-api';

export default {
    name: 'PositionInfoPanel',
    emits: [],
    components: {
    },
    setup(props, { root }){
        const store = root.$store;
		
		const issueChecklist = computed(() => store.getters['population/getChecklist'])
		
        return {
			issueChecklist,
		
        }

    },
};
</script>