<template>
    <v-dialog
        v-model="showDialog"
        max-width="850px"
        persistent
        scrollable
    >
        <template v-slot:activator="{ on: dialog, attrs }">
            <v-tooltip bottom open-delay="750">
                <template v-slot:activator="{on: tooltip}">
                    <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="{...tooltip, ...dialog}"
                        @click="loadChecklist(item)"
                    >mdi-format-list-checkbox</v-icon>
                </template>
                <span>{{ tooltipText }}</span>
            </v-tooltip>
        </template>

        <v-card>
            <v-card-title class="headline mb-2">
                {{ title }}
            </v-card-title>
            
            <v-card-text v-if="!loading">
                <v-expansion-panels
                    v-model="panels"
                    multiple
                >
                    <!-- <HRChecklist v-if="$route.path === '/hr/newhires'" /> -->
                    <TicketsPanel @reload="loadChecklist(item)" />
                    <PositionInfoPanel />
                </v-expansion-panels>
            </v-card-text>
            <v-card-text v-if="loading">
                <v-row class="fill-height text-center" align-content="center" justify="center" style="height: 200px">
                    <v-col cols="8">
                        <div class="text-h6 font-weight-light">Loading checklist...</div>
                        <v-progress-linear
                            indeterminate
                            rounded
                            height="3"
                        ></v-progress-linear>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="reset">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatDistanceToNow, parseISO } from 'date-fns';
// import HRChecklist from '@/components/population/checklist/HRChecklist'
import TicketsPanel from '@/components/population/checklist/TicketsPanel'
import PositionInfoPanel from '@/components/population/checklist/PositionInfoPanel'

export default {
    name: "ChecklistDialog",
    props: {
        item: {
            type: Object
        },
        type: {
            type: String
        }
    },
    data() {
        return {
            showDialog: false,
            loading: false,
            panels: [0],
            first_load: true,
        };
    },
    components: {
        // HRChecklist,
        TicketsPanel,
        PositionInfoPanel
    },
    computed: {
        ...mapGetters({
            issueChecklist: 'population/getChecklist'
        }),
        title(){
            return this.$route.path === '/hr/newhires' ? `${this.item.key} Position Information` : `${this.item.key} Termination Tasks`; 
        },
        tooltipText(){
            return this.$route.path === '/hr/newhires' ? `Position Information` : `Termination Tasks`; 
        }
    },
    watch:{
    },
    methods: {
        ...mapActions({
        }),
        reset(){
            this.showDialog = false;
        },
        async loadChecklist(item) {
            this.loading = true;
            await this.$store.dispatch('population/loadChecklist', {jiraKey: item.key, type: this.type});
            this.loading = false;
        },
        getDateDistance(dt) {
            return formatDistanceToNow(parseISO(dt));
        },
        parseISO: parseISO,
    },
};
</script>
<style>
.defCursor input[role="checkbox"] {
    cursor: default;
}
</style>