<template>
    <div style="display: contents">
        <v-tooltip bottom open-delay="750">
            <template v-slot:activator="{on, attrs}">
                <v-badge
                    :value="item.needsApproval"
                    color="orange"
                    icon="mdi-exclamation-thick"
                    offset-x="22"
                    offset-y="13"
                    style="cursor: pointer"
                    bordered
                    @click.native.stop="$emit('editReq')"
                    v-if="options.allowEdit !== false"
                >
                    <v-icon
                        v-if="options.allowEdit !== false"
                        class="mr-2"
                        @click.stop="$emit('editReq')"
                        v-on="on"
                        v-bind="attrs"
                    >
                        mdi-pencil
                    </v-icon>
                </v-badge>
            </template>
            <span v-if="item.needsApproval">Edit (Pending Approval)</span>
            <span v-else>Edit</span>
        </v-tooltip>

        <JiraCommentDialog :item="item" @commentAdded="$emit('commentAdded')" />

        <JiraAttachmentDialog :item="item" @reload="$store.dispatch('population/loadNewHires')" />

        <TimelineDialog :item="item" />

        <TransitionDialog
            v-if="(item.status === 'Division Review' || item.status === 'Open' || (item.status == 'Staffing Xpress' && item.candidateInfo.hasCandidate)) && !item.needsApproval && item.meta.validAttach"
            :item="item"
        />

        <ChecklistDialog
            :item="item"
            type="hr"
        />
        <!-- <router-link
            v-if="preOnboardingStatuses.indexOf(item.status) > -1 ||
                (facultyFellowStatuses.indexOf(item.status) > -1 && ['Faculty', 'Fellow'].indexOf(item.issue_type_short) > -1)"
            style="text-decoration: none; color: inherit"
            :to="{ name: 'newCandidate', params: {'jiraKey': item.key}}">
            <v-tooltip bottom open-delay="750">
                <template v-slot:activator="{on, attrs}">
                    <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                        mdi-account-arrow-right
                    </v-icon>
                </template>
                <span>Onboarding form</span>
            </v-tooltip>
        </router-link> -->
        

        <router-link
            v-if="isPreOnboardingStatus"
            style="text-decoration: none; color: inherit"
            :to="{ name: 'newCandidate', params: {'jiraKey': item.key}}">
            <v-tooltip bottom open-delay="750">
                <template v-slot:activator="{on, attrs}">
                    <v-badge
                        :value="candidateNeedsApproval"
                        color="orange"
                        icon="mdi-exclamation-thick"
                        offset-x="20"
                        offset-y="13"
                        style="cursor: pointer"
                        bordered
                        v-if="item.candidateInfo.hasCandidate"
                    >
                        <v-icon
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-account-arrow-right
                        </v-icon>
                    </v-badge>
                    <v-badge
                        :value="hasNoCandidate"
                        color="blue darken-1"
                        icon="mdi-plus"
                        offset-x="20"
                        offset-y="13"
                        style="cursor: pointer"
                        bordered
                        v-else-if="hasNoCandidate"
                    >
                        <v-icon
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-account-arrow-right
                        </v-icon>
                    </v-badge>
                    <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        v-else
                    >
                        mdi-account-arrow-right
                    </v-icon>
                </template>
                <span v-if="hasNoCandidate">Onboarding Form (Incomplete)</span>
                <span v-else-if="candidateNeedsApproval">Onboarding Form (Needs Approval)</span>
                <span v-else>Onboarding form</span>
            </v-tooltip>
        </router-link>



        <CancelDialog
            v-if="options.allowCancel !== false"
            :item="item"
            @reloadData="$emit('reloadData')"
        />
    </div>
</template>
<script>
import TransitionDialog from '@/components/population/newhire/TransitionDialog';
import JiraCommentDialog from '@/components/population/JiraCommentDialog';
import JiraAttachmentDialog from '@/components/population/JiraAttachmentDialog';
import CancelDialog from '@/components/population/newhire/CancelDialog';
import ChecklistDialog from '@/components/population/checklist/ChecklistDialog';
import TimelineDialog from '@/components/population/TimelineDialog';

export default {
    name: 'NewHireActions',
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
        options: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    data() {
        return {
            preOnboardingStatuses: ['Interviewing', 'Finance Validation', 'HR Clearance', 'Pre-Offer Process', 'Post-Offer Process', 'Department Hosting', 'Pre-Onboarding'],
            facultyFellowStatuses: ['HR Review', 'Pending Faculty Affairs'],
            interviewingStatuses: ['Interviewing', 'HR Review', 'Pending Faculty Affairs'],
        };
    },
    components: {
        TransitionDialog,
        JiraCommentDialog,
        JiraAttachmentDialog,
        CancelDialog,
        ChecklistDialog,
        TimelineDialog
    },
    computed: {
        isPreOnboardingStatus() {
            return (
                this.preOnboardingStatuses.indexOf(this.item.status) > -1 ||
                (
                    this.facultyFellowStatuses.indexOf(this.item.status) > -1 && 
                    ['Faculty', 'Fellow'].indexOf(this.item.issue_type_short) > -1
                )
            )
        },
        candidateNeedsApproval() {
            return (
                this.item.status == 'Interviewing' && 
                this.item.candidateInfo.hasCandidate && 
                !('curStep' in this.item.candidateInfo.data)
            )
        },
        hasNoCandidate() {
            return (
                this.interviewingStatuses.indexOf(this.item.status) > -1 &&
                !this.item.candidateInfo.hasCandidate
            )
        }
    },
    methods: {},
};
</script>